.flex-table {
  display: block;
  width: fit-content;

  thead, tbody, tfoot, th, td {
    display: block;
    width: fit-content;
  }

  tr {
    display: flex;
  }
}
