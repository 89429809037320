.has-hovered-contents {
  position: relative;
}

.hovered-commenter {
  position: absolute;
  top: -7px;
  right: -12px;
  z-index: 10;
}

.has-hovered-contents:not(:hover) .hovered-commenter:not(.always-visible) {
  display: none;
}
