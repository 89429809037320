@import '../../variables';

tr.sub-row td,
tr.sub-row th {
  border-top: none;
}

tr.sub-row th {
  padding-left: 2rem;
}
