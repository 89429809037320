.has-hovered-noter {
  position: relative;
}

.hovered-noter {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index: 10;
}

.has-hovered-contents:not(:hover) .hovered-noter:not(.always-visible) {
  display: none;
}
