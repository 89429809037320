@import './sticky-table.css';
@import './react-resizable.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-theme-light {
  background-color: #ffe1df;
}

.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1rem;
}
.gap-4 {
  gap: 1.5rem;
}
.c-gap-1 {
  column-gap: 0.25rem;
}
.c-gap-2 {
  column-gap: 0.5rem;
}
.c-gap-3 {
  column-gap: 1rem;
}
.c-gap-4 {
  column-gap: 1.5rem;
}
.r-gap-1 {
  row-gap: 0.25rem;
}
.r-gap-2 {
  row-gap: 0.5rem;
}
.r-gap-3 {
  row-gap: 1rem;
}
.r-gap-4 {
  row-gap: 1.5rem;
}
